import { createAsyncThunk } from "@reduxjs/toolkit";
import { newRelic } from "../utility/newrelic";

export const fetchMerchantInfo = createAsyncThunk(
  "merchant/fetchInfo",
  async (merchantToken) => {
    try {
      const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/merchant/v1/info`;
      const response = await fetch(url, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
        },
      });

      if (!response.ok) {
        const info = await response.json();
        newRelic.log(`Ecomm_Menu - Merchant Info Error ${sessionStorage.getItem('merchantSlug')}`, { customAttributes: { response: JSON.stringify(info), url: url }});
        throw new Error("Failed to fetch merchant info");
      }

      const data = await response.json();
      return {...data,merchant:{...data?.merchant,venues:data?.merchant?.venues.map((vn,index)=>{
        if(index === 0){
          return {...vn,selected:true}
        }else{
          return {...vn,selected:false}
        }
      })}};
    } catch (error) {
      newRelic.log(`Ecomm_Menu - Merchant Info Error ${sessionStorage.getItem('merchantSlug')}`, { customAttributes: {response: JSON.stringify(error)}});
      throw new Error(error.message);
    }
  }
);
