import { createAsyncThunk } from "@reduxjs/toolkit";
import { newRelic } from "../utility/newrelic";

export const getDiscountData = createAsyncThunk("discount", async (payload) => {
    const selectedVenue = payload || sessionStorage.getItem("selectedVenue");

try {
  const discountUrl = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${selectedVenue}/v1/coupons`;

  const response = await fetch(discountUrl, {
      headers: {
        merchantToken: sessionStorage.getItem("merchantToken"),
        authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
      },
    });

    if (!response.ok) {
      const info = await response.json();
      newRelic.log(`Ecomm_Menu - Discount Error ${selectedVenue}`, {customAttributes: {response: JSON.stringify(info), url: discountUrl}});
      throw new Error("No data found");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    newRelic.log(`Ecomm_Menu - Discount Error ${selectedVenue}`, {customAttributes: {response: JSON.stringify(error)}});
    throw new Error(error.message);

}
});
