import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StaticBanner from "./StaticBanner.js";
import CarouselBanner from "./CarouselBanner.js";
import ProductCategory from "./ProductCategory.js";
import Heading from "./Header.js";
import Shortcuts from "./Shortcuts.js";
import { notNull } from "../sharedFunctions.js";
import { useLocation } from "react-router-dom";
import { fetchMenuFeedApi } from "../store/MenuFeedaction.js";

const Home = (props) => {
  const components = useSelector((state) => state?.content?.contentData?.components);

  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const recache = queryParams.get('recache');

  if (recache) {
    dispatch(fetchMenuFeedApi({ recache: true }));
  }
  return (
    <>
      {
        notNull(components) && components.map((component,index) => {
          switch (component.type) {
            case "banner":
              return <StaticBanner key={index} component={component}/>
            case "heading":
              return <Heading key={index}/>;
            case "carousel":
              return <CarouselBanner key={index} component={component}/>
            case "category":
              return <ProductCategory key={index} component={component}/>;
            case "shortcut":
              return <Shortcuts key={index} component={component}/>;
            default:
              return null; // Handle other cases if necessary
          }
        })
      }
    </>
  )
}

export default Home
