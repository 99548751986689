import { Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { notNull } from '../sharedFunctions';
import { ReactSVG } from 'react-svg';

export default function Shortcut({display_icons, item}) {
  const {name, category_id, subcategory_id, text_color, icon_color, icon} = item
  const categories = useSelector((state) => state?.menuFeed?.info?.product_data?.categories);
  const category = categories?.find(cat => cat.id === category_id)
  const subcategories = useSelector((state) => state?.menuFeed?.info?.product_data?.categories?.map(cat => cat.subcategories).flat());
  const subcategory = subcategories?.find(subcat => subcat.id === subcategory_id)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)

  const apiHost = sessionStorage.getItem('apiHost')

  const image_url = `${apiHost}/assets/${icon}`

  useEffect(() => {
    if (categories) {
      setLoading(false)
    }
  }, [categories])

  const handleClick = () => {
    // debugger
    if(notNull(category)){
      navigate(`products/?category=${category.safe_name}`)
    }else if(notNull(subcategory)){
      navigate(`products/?subcategory=${subcategory.safe_name}`)
    }
  }


  return (
    <Button
      loading={loading}
      size='large'
      icon={<ReactSVG src={image_url} alt={name} style={{width:20, fill: icon_color}}
      beforeInjection={(svg) => {
        svg.setAttribute('style', `fill: ${icon_color}`);
      }}
    />}
      onClick={handleClick}
      disabled={loading}
    >
      <Typography.Text style={{color: text_color}}>
        {name}
      </Typography.Text>
    </Button>
  )
}
