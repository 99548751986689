import { createAsyncThunk } from "@reduxjs/toolkit";
import { newRelic } from "../utility/newrelic";

export const fetchQuoteCartInfoApi = createAsyncThunk(
  "initializeQuotes/fetch",
  async (reference_no, payload) => {
    try {
      const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${sessionStorage.getItem("selectedVenue")}/v1/quotes/${reference_no}`;
      const response = await fetch(url, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
          contentType: "application/json",
        //   authorization: 'Bearer 1vm1ax-tIxpocYteXD1FWA',
        },
      });

      if (!response.ok) {
        const info = await response.json();
        newRelic.log(`Ecomm_Menu - Fetch Quote Cart Info Error ${reference_no}`, { customAttributes: {response: JSON.stringify(info), url: url }});
        throw new Error("Failed to fetch get Cart info info");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      newRelic.log(`Ecomm_Menu - Fetch Quote Cart Info Error ${reference_no}`, { customAttributes: {response: JSON.stringify(error)}});
      throw new Error(error.message);
    }
  }
);
