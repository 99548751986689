import { createAsyncThunk } from "@reduxjs/toolkit";
import { newRelic } from "../utility/newrelic";

export const fetchContentData = createAsyncThunk(
  "content/fetchContentData",
  async (payload, thunkAPI) => {
    const selectedVenue = payload || sessionStorage.getItem("selectedVenue");

    try {
      const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${selectedVenue}/v1/menu_feeds/${sessionStorage.getItem("feedId")}/content`;
      const response = await fetch(url, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
          authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
        },
      });

      if (!response.ok) {
        const info = await response.json();
        newRelic.log(`Ecomm_Menu - Content Error ${selectedVenue}`, { customAttributes: {response: JSON.stringify(info), url: url }});
        throw new Error("No data found");
      }

      const data = await response.json();
      newRelic.log(`Ecomm_Menu - Content ${selectedVenue}`, { customAttributes: {  response: JSON.stringify(data)} });
      return data;
    } catch (error) {
      newRelic.log(`Ecomm_Menu - Content Error ${selectedVenue}`, {customAttributes: {response: JSON.stringify(error)}});
      throw new Error(error.message);
    }
  }
);
