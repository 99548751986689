import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';


const options = {
  init: { page_view_timing: { enabled: true } },
  info: {
    applicationID: '1120352673',
    licenseKey: 'a8b25f1662',
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
  },
  loader_config: {
    agentID: '1120352673',
    accountID: '1002792',
    trustKey: '1002792',
  },
};

const agent = new BrowserAgent(options);

export const newRelic = agent.api;
